<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useDownloadCategoriesAction, useDownloadCategoriesQuery } from '@/account/composables/downloads';
    import { useAsyncTask } from 'vue-concurrency';
    import AccordionTab from 'primevue/accordiontab';
    import Button from 'primevue/button';
    import Select from 'primevue/select';
    import Accordion from 'primevue/accordion';
    import { downloadApi } from '@containex/portal-backend-api-client';
    import useGlobalToast from '@/composables/useGlobalToast';
    import { DownloadFileDto, ProductType } from '@containex/portal-backend-dto';
    import { computed, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { stringValueOfParam } from '@/util/param';
    import { useI18n } from 'vue-i18n';
    import { immediatelyDownloadFile } from '@/util/download';
    import { getLogger } from '@/logger/logger';
    import { isMobile } from '@/util/breakpoints';
    import { httpClient } from '@/common/api/http-client';

    const { t } = useI18n();
    const { downloadCategories } = useDownloadCategoriesQuery();
    const downloadCategoriesAction = useDownloadCategoriesAction();
    const { errorToastOptions, addToast } = useGlobalToast();
    const logger = getLogger('DownloadsView');

    const route = useRoute();

    const productTypes = Object.values(ProductType);
    const selectedProductType = ref(ProductType.AllProductTypes);

    let selectedLanguage = computed(() => stringValueOfParam(route.params.language));
    const downloadTaskPerform = useAsyncTask(async () => {
        await downloadCategoriesAction.fetchDownloadCategories(selectedLanguage.value);
    });
    let downloadTaskResult = downloadTaskPerform.perform();

    watch(
        () => route.params.language,
        (newLanguage) => {
            selectedLanguage = computed(() => stringValueOfParam(newLanguage));
            downloadTaskResult = downloadTaskPerform.perform();
        }
    );

    async function startDownloadThroughId(fileId: number, fileName: string): Promise<void> {
        try {
            const response = await downloadApi.fetchDownloadFile(httpClient, fileId);

            if (response.data.url != null) {
                await immediatelyDownloadFile(response.data.url, fileName);
            }
        } catch (error) {
            logger.error(error, 'Error happened while downloading file');
            addToast(errorToastOptions);
        }
    }

    function filteredDownloads(downloads: DownloadFileDto[]): DownloadFileDto[] {
        const selected = selectedProductType.value;
        if (selected === ProductType.AllProductTypes) {
            return downloads;
        }

        return downloads.filter((download) => download.product_type?.includes(selected));
    }

    const filteredDownloadCategories = computed(() => {
        return downloadCategories.value.filter((dc) => filteredDownloads(dc.downloads).length > 0);
    });
</script>
<template>
    <div class="download-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.MY_DOWNLOADS') }}</h2>
        <template v-if="downloadTaskResult.isSuccessful">
            <div v-if="(downloadCategories?.length ?? 0) > 0" class="download-categories">
                <Select v-model="selectedProductType" :options="productTypes" class="product-category-selection">
                    <template #value="slotProps">
                        {{ t(`ACCOUNT.PRODUCT_TYPES.${slotProps.value}`) }}
                    </template>
                    <template #option="slotProps">
                        {{ t(`ACCOUNT.PRODUCT_TYPES.${slotProps.option}`) }}
                    </template>
                </Select>

                <div v-if="filteredDownloadCategories.length > 0" class="download-accordion">
                    <Accordion
                        :multiple="true"
                        expand-icon="pi pi-angle-right"
                        collapse-icon="pi pi-angle-down"
                        :active-index="[]"
                    >
                        <template v-for="downloadCategory in filteredDownloadCategories" :key="downloadCategory.title">
                            <AccordionTab>
                                <template #header>
                                    <div class="text-base-semibold-line-height-auto">
                                        {{ downloadCategory.title }} ({{
                                            filteredDownloads(downloadCategory.downloads).length
                                        }})
                                    </div>
                                </template>
                                <template
                                    v-for="downloadItem in filteredDownloads(downloadCategory.downloads)"
                                    :key="downloadItem.fileId"
                                >
                                    <div class="wrapper">
                                        <div>
                                            {{ downloadItem.title }}
                                            <span class="download-description">{{ downloadItem.description }}</span>
                                        </div>
                                        <div>
                                            <Button
                                                class="download-button"
                                                icon="pi pi-download"
                                                icon-pos="left"
                                                outlined
                                                :aria-label="t('COMMON.DOWNLOAD')"
                                                :label="isMobile ? undefined : t('COMMON.DOWNLOAD')"
                                                @click="startDownloadThroughId(downloadItem.fileId, downloadItem.title)"
                                            />
                                        </div>
                                    </div>
                                </template>
                            </AccordionTab>
                        </template>
                    </Accordion>
                </div>
                <p v-else>{{ t('ACCOUNT.NO_DOWNLOADS') }}</p>
            </div>
            <p v-else>{{ t('ACCOUNT.NO_DOWNLOADS') }}</p>
        </template>
        <LoadingSpinner v-else-if="downloadTaskResult.isRunning" />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .download-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        padding: main.$spacing-4;
        border-bottom: 1px solid main.$color-background-lightgray;
        align-items: center;
        gap: main.$spacing-6;
    }

    .wrapper:last-child {
        border-bottom: 0;
    }

    .wrapper div {
        vertical-align: middle;
    }

    .download-description {
        display: block;
        color: main.$color-secondary;
    }

    .product-category-selection {
        width: 100%;
    }

    @include main.for-breakpoint-md {
        .product-category-selection {
            width: 25%;
        }
    }

    :deep(.p-accordion) {
        margin-top: main.$spacing-6;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-6;
    }

    select {
        padding: 7px 40px 7px 12px;
        width: 100%;
        border: 1px solid #e8eaed;
        border-radius: 5px;
        background: white;
        box-shadow: 0 1px 3px -2px #9098a9;
        cursor: pointer;
        font-size: 16px;
        transition: all 150ms ease;
    }

    option:focus {
        outline: none;
        border-color: main.$color-primary-500;
        box-shadow: 0 0 0 2px rgba(0, 119, 255, 20%);
    }

    option:hover {
        stroke: main.$color-primary-500;
    }

    .download-button {
        vertical-align: middle;
        border: 1px solid main.$color-primary-500;
    }

    .download-categories {
        :deep(.p-accordion-header-link) {
            font-weight: 700;
        }

        :deep(.p-accordion-content) {
            padding: 0;
        }

        :deep(.p-accordion-tab) {
            border: none;
        }
    }

    :deep(.p-button-label) {
        font-weight: bold;
    }
</style>
